/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import axios from 'axios';
import jwt_decode from "jwt-decode";
import Navbar from "../Navbar";
import Header from "../Header";
import baseUrl from "../BaseUrl";
import Pagination from "react-js-pagination";
import formatDateTimeToPST from "../PSTDateConvert";
import CarrierResponse from "./comman/CarrierResponse";
import CustomerStatusResponse from "./comman/CustomerStatusResponse";
import OrderDetailLog from "./comman/OrderDetailLog";
import OrderSummaryLog from "./comman/OrderSummaryDetail";
import OrderDetailFilter from "./comman/OrderDetailFilter";
import Loader from "../Loader";
import { saveAs } from 'file-saver';
import { useNavigate, Link, useParams, useLocation } from 'react-router-dom';

 
const ViewOrderDetail = () => {
    const { id } = useParams();
    const [name, setName] = useState('');
    const [timeZone, seTimeZone] = useState('');
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [orders, setOrders] = useState([]);
    const [error, setError] = useState(null);
    const [role, setRole] = useState('');
    const [access, setAccess] = useState([]);
    const [detail, setDetail] = useState([]);
    const [orderSummary, setOrderSummary] = useState([]);
    const [allNumberList, setAllNumberList] = useState([]);
    const [dmtList, setDmtList] = useState([]);
    const [digitMapping, setDigitMapping] = useState(false);
    const [page, setPage] = useState(1);
    const [totalItem, setTotalItem] = useState(0);
    const [popupOpen, setPopupOpen] = useState(false);
    const [msg, setMsg] = useState('');
    const [isSuccess, setIsSuccess] = useState(false);
    const [selectedValue, setSelectedValue] = useState('');
    const [orderFilterData, setOrderFilterData] = useState({});
    const [carriers, setCarriers] = useState([]);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const type = queryParams.get('type');
    
    const navigate = useNavigate();
 
    useEffect(() => {
        refreshToken();
        getUserAccess();
        getOrderDetail(0);
    }, []);


    useEffect(() => {
        if(access.length > 0 && token){
            checkAccess(access, token);
        }
    }, [access, token]);
    useEffect(() => {
        if(token){
            getCarrierList();
        }
    }, [token]);

    useEffect(() => {
        if(detail?.cid && detail?.order_type === 0){
            getCustomerDigitMappingAccess();
        }
    }, [detail]);

    const getCustomerDigitMappingAccess = async () => {
        try {
            const response = await axios.get(`${baseUrl}/check-company-e911-access/${detail.cid}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
            setDigitMapping(response.data.digit_mapping_switch === 1 ? true : false);
            
        } catch (error) {
           console.log(error);
        }
    };

    const getCarrierList = async () => {
        setPopupOpen(true);
        const response = await axios.get(`${baseUrl}/carrier-settings?page=${page}&limit=${2000}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        setPopupOpen(false);
        setCarriers(response.data.data);
    }
 
    const refreshToken = async () => {
        try {
            const response = await axios.get(`${baseUrl}/token`);
            
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            console.log(decoded.exp);
            setName(decoded.name);
            seTimeZone(decoded.timezone);
            setRole(decoded.role)
            setExpire(decoded.exp);
        } catch (error) {
           
            if (error.response) {
                // setError("Unauthorized");
                navigate("/");  
                window.location.reload();
            }
        }
    }
    const getUserAccess = async () => {
        try {
            const response = await axiosJWT.get(`${baseUrl}/user-access`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
           
            console.log(response.data.results);
            setAccess(response.data.results);
            // setIsLoading(false);
            
        } catch (error) {
           console.log(error);
        //    setIsLoading(false);
            // if (error.response) {
            //     // setError("Unauthorized");
            //     navigate("/");  
            //     window.location.reload();
            // }
        }
    }

    const handlePageChange  = (pageNumber) => {
        
        setPopupOpen(true);
        const limit = 20;
        const startIndex = (pageNumber - 1) * limit;
        const endIndex = pageNumber * limit;
    
        const slicedData = allNumberList?.slice(startIndex, endIndex);
        setOrders(slicedData);
        setPage(pageNumber);
        setPopupOpen(false);
    }
    const getOrderDetail = async (flag) => {
        setPopupOpen(true);
        const response = await axiosJWT.get(`${baseUrl}/order-detail/${atob(id)}?export=${flag}&orderFilterData=${JSON.stringify(orderFilterData)}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        setPopupOpen(false);
        const data = response.data?.data?.[0]?.tn_details;
        const limit = 20; // Replace with your actual limit
        const startIndex = (page - 1) * limit;
        const endIndex = page * limit;
    
        const slicedData = data?.slice(startIndex, endIndex);
       
        
        
        setDetail(response.data?.data?.[0]);
        setAllNumberList(data);
        setDmtList([response.data.dmtList]);
        setOrderSummary(response.data.orderSummary);
        setSelectedValue(response.data?.data?.[0]?.order_status);
            if(flag == 1){
                handleExportCSV(data);
            }else{
                setOrders(slicedData);
            }
        
        setTotalItem(response.data?.data?.[0].tn_details.length);
    }
    const hasPermission = (permission) => {
        return access.some((item) => item.access === permission);
    };

    const checkAccess = async (access) => {
        if(!hasPermission('ordernewnumber')){
            navigate('/dashboard');
        }
    }

    const changeStatus = async(e) => {
        var orderStatus = e.target.value;
        setSelectedValue(orderStatus);               
        
    };

    const handleOrderStatusChange = async() => {
        try {
            let callApi = false;
            const supported = detail.tn_details.filter((item) => item.number_type == 1 || item.number_type == 0);
            // const notSupported = detail.tn_details.filter((item) => item.number_type >= 2);
            let propmtMsg;
            if(supported.length > 0 ){
                propmtMsg= "The number has been removed from the customer inventory and from the carrier, please confirm that it has been manually removed from the Sansay and Rome/NSS";
            }else{
                propmtMsg = "The number has been removed from the customer inventory and from the Sansay, please confirm that it has been manually removed from the carrier and Rome/NSS";
            }
            
            if(detail?.order_type == 2 && selectedValue == 1){
                const userResponse = window.confirm(propmtMsg);
                if (userResponse) {
                    callApi = true;
                } else {
                    callApi = false;
                }
            }else{
                callApi = true;
            }
            if(callApi == true){
                const response = await axiosJWT.post(`${baseUrl}/order-status-change/${atob(id)}`, {order_status: selectedValue},{
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                console.log(response);
                setIsSuccess(true);
                setMsg(response.data.msg);
            }
            
            
        } catch (error) {
            setIsSuccess(false);
            setMsg(error.response.data.msg);
        }
    
        
    };
 
    const axiosJWT = axios.create();
 
    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(`${baseUrl}/token`);
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setName(decoded.name);
            seTimeZone(decoded.timezone);
            setRole(decoded.role)
            setExpire(decoded.exp);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });


    const handleExportCSV = async (exportOrders) => {
        // e.preventDefault();
        // console.log(exportOrders)

        const headerRow = ["Tel Number"];

        if (detail.order_type === 4 || detail.order_type == 5) {
            headerRow.push('Overall Status')
            { role <= 2 && headerRow.push('Alias') }
            { role <= 2 && headerRow.push('Origination ANI') }
            { role <= 2 && headerRow.push('Match Length') }
            { role <= 2 && headerRow.push('Origination DNIS') }
            { role <= 2 && headerRow.push('Match Length') }
            { role <= 2 && headerRow.push('Translated ANI') }
            { role <= 2 && headerRow.push('Translated DNIS') }
            { role <= 2 && headerRow.push('ANI Action') }
            { role <= 2 && headerRow.push('DNIS Action') }

            const csv = [headerRow].concat(
                exportOrders.map((order, index) => {
                    console.log(order)
                    const tn_number = order.tn_number;
                    let resultArray = [tn_number];
                    resultArray.push(order.user_status == 2 ? "Failed" : (order.user_status == 1 ? "Complete" : "In Progress"));
                    resultArray.push(order.alias)
                    resultArray.push(order.original_ani)
                    resultArray.push(`${order.ani_min_length}-${order.ani_max_length}`)
                    resultArray.push(order.original_dnis)
                    resultArray.push(`${order.dnis_min_length}-${order.dnis_max_length}`)
                    resultArray.push(order.translated_ani)
                    resultArray.push(order.translated_dnis)
                    resultArray.push(order.ani_action == 0 ? "all" : (order.ani_action == 2 ? "passthrough" : (order.ani_action == 3 ? "prepend" : "match")))
                    resultArray.push(order.dnis_action == 0 ? "all" : (order.dnis_action == 2 ? "passthrough" : (order.dnis_action == 3 ? "noLNP" : (order.dnis_action == 4 ? "prepend" : "match"))))

                    return resultArray.join(',');

                })
            ).join('\n')

            console.log(csv)

            const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
            saveAs(blob, `order_Detail_${detail.unique_id}.csv`);

        } else {
            { detail.order_type == 0 && headerRow.push('Province') }
            { detail.order_type == 0 && headerRow.push('Rate Center') }
            { detail.order_type != 1 && headerRow.push('Route Option') }
            { role <= 2 && headerRow.push('Carrier') }
            { role <= 2 && headerRow.push('CNAM') }
            { role <= 2 && headerRow.push('E911') }
            { role <= 2 && headerRow.push('Sansay') }
            { role <= 2 && headerRow.push('ROME') }
            { role <= 2 && (detail.order_type == 0 || detail?.order_type == 2) && headerRow.push('Carrier Status') }
            headerRow.push('Overall Status')

            const csv = [headerRow].concat(
                exportOrders.map((order, index) => {
                    const tn_number = order.tn_number;
                    const route_option = order.route_option;
                    let resultArray = [tn_number];
                    if (detail.order_type == 0) {
                        resultArray.push(order.province);
                        resultArray.push(order.rate_center);
                    }
                    if (detail.order_type != 1) {
                        resultArray.push(order.route_option);
                    }
                    if (role <= 2) {
                        resultArray.push(getCarrierName(order.number_type));
                        resultArray.push(order.number_status == 2 ? "-" : (order.cname_status == 1 ? order.cname_label : (order.cname_status == 2 ? "Failed" : (order.cname_status == 0 && order.cname_flag == 1 ? "In Progress" : "-"))));
                        resultArray.push(order.e911_status == 1 ? "Complete" : (order.e911_status == 2 ? "Failed" : "-"));
                        resultArray.push(order.number_status == 2 ? "-" : (order.sansay_status == 1 ? "Complete" : (order.sansay_status == 2 ? "Failed" : "-")));
                        resultArray.push(order.number_status == 2 ? "-" : (order.rome_status == 1 ? "Complete" : (order.rome_status == 2 ? "Failed" : "-")));
                    }
                    if (role <= 2 && (detail.order_type == 0 || detail?.order_type == 2)) {
                        resultArray.push(order.number_status == 2 ? "Failed" : (order.number_status == 1 ? "Complete" : "In Progress"));
                    }
                    if (detail.order_type == 0 && order.cname_flag == 1) {
                        resultArray.push((order.cname_status == 2 && order.number_status == 2) ? "Failed" : ((order.cname_status == 1 && order.number_status == 1) ? "Complete" : (((order.cname_status == 2 && order.number_status == 1) || (order.user_status == 1 && order.number_status == 2)) ? "Failed" : "In Progress")));
                    }
                    if (detail.order_type == 0 && order.cname_flag == 0) {
                        resultArray.push(order.user_status == 2 ? "Failed" : (order.user_status == 1 ? "Complete" : "In Progress"));
                    }
                    if (detail.order_type != 0) {
                        resultArray.push(order.user_status == 2 ? "Failed" : (order.user_status == 1 ? "Complete" : "In Progress"));
                    }
                    return resultArray.join(',');
                })).join('\n');
            const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
            saveAs(blob, `order_Detail_${detail.unique_id}.csv`);
        }


    }
    const handleFilterData = (filterData) => {
        
        const filterList = allNumberList.filter(item => {
            const conditions = [
              filterData.overallStatus !== undefined && filterData.overallStatus !== null ? item.user_status == filterData.overallStatus : true,
              filterData.selectCarrier !== undefined && filterData.selectCarrier !== null ? item.number_type == filterData.selectCarrier : true,
              filterData.numberStatus !== undefined && filterData.numberStatus !== null ? item.number_status == filterData.numberStatus : true,
              filterData.sansayStatus !== undefined && filterData.sansayStatus !== null ? item.sansay_status == filterData.sansayStatus : true,
              filterData.romeStatus !== undefined && filterData.romeStatus !== null ? item.rome_status == filterData.romeStatus : true,
              filterData.cnameStatus !== undefined && filterData.cnameStatus !== null ? item.cname_status == filterData.cnameStatus : true,
              filterData.e911Status !== undefined && filterData.e911Status !== null ? item.e911_status == filterData.e911Status : true
            ];
            
            return conditions.every(condition => condition);
          });
        
        const limit = 20; // Replace with your actual limit
        const startIndex = (1 - 1) * limit;
        const endIndex = 1 * limit;
        const slicedData = filterList?.slice(startIndex, endIndex);
        setOrders(slicedData);
        setTotalItem(filterList.length);
    }

    const addOrderNumberDmt = async (e) => {
        e.preventDefault();
        const numberList = detail.tn_details.filter(item => {
            const allRequiredFlags = item.user_status === 1 && item.rome_status === 1 && item.sansay_status === 1 && item.number_status === 1;                    
            return (item.cname_flag === 1 && allRequiredFlags && item.cname_status === 1) || (item.cname_flag === 0 && allRequiredFlags);
        });
        if(numberList.length === 0){
            alert("There are no any success number, so not avaialbe for dmt.");
            return;
        }else{
            navigate("/digit-mapping/add-multiple", { state: {compid : detail.cid, successNumberList: numberList} })
        }
    }

    const getCarrierName = (number_type) => {
        if(number_type == 1){
            return "Bandwidth";
        }else if(number_type == 0){
            return "Inteliquent";
        }else{
            const name = carriers.find((item) => item.carrier_index == number_type)?.name ?? "-";
            return name;
        }
    }

 
    return (
        <div className="panelbox">
           <Navbar  token={token} access = {access}/>
            <div className="right-panel">
            <Header name={name} />
                <div className="content-page">
                {popupOpen && (
                     <Loader /> 
                ) }
               
                    <div className="row ">
                    <p className={`has-text-centered ${isSuccess ? 'success' : 'error'}`}>{msg}</p>
                        <div className="col-8">
                            <div className="left-title">
                                <h1>Orders Detail</h1>
                            </div>
                        </div>
                        <div className="col-4 inventory-btn">
                            <div className="addnew-button">
                            <Link to="/view-order"><span>&larr;</span> Back</Link>
                                
                                
                            <a className="newrow" onClick={(e) => {getOrderDetail( 1);}} href="#">Export</a>
                            {digitMapping && detail.order_type === 0 && <a className="search" onClick={(e) => addOrderNumberDmt(e)} href="#">Add DMT</a>}
                        
                            </div>
                        </div>
                    </div>
                    <div className="row order-detail">
                        <div className="col-4">
                            <div><strong>Customer Order ID:</strong> {detail?.unique_id}</div>
                            {role <=2 && (detail?.order_type != 1 && detail?.order_type !== 4 && detail?.order_type !== 5) && (detail?.carrier_type == 0 || detail?.carrier_type == 2) &&<div><strong>Inteliquent Order Id:</strong> {detail.order_id}</div>}
                            {role <=2 && (detail?.order_type != 1 && detail?.order_type !== 4 && detail?.order_type !== 5) && (detail?.carrier_type == 1 || detail?.carrier_type == 2) && <div><strong>Bandwidth Order Id:</strong> {detail.bandwidth_order_id}</div>}  
                            {role <=2 && (detail?.order_type == 0 && detail?.order_type !== 4 && detail?.order_type !== 5) && (orders?.[0]?.cname_label != "")  && (detail.bandwidth_cname_order_id != null) && <div><strong>Bandwidth Cname Order Id:</strong> {detail.bandwidth_cname_order_id}</div>}  
                        </div>
                        <div className="col-4">
                           <div><strong>Order Type:</strong> {detail?.order_type == 0 ? "New" : (detail?.order_type == 1 ? "Change" :  (detail?.order_type == 2 ? "Disconnect" : (detail?.order_type === 3 ? "Feature" : (detail?.order_type === 4 ? 'New DMT' : 'Delete DMT'))))}</div>
                           <div><strong>Order Date ({timeZone}) :</strong> {detail?.created_at ? formatDateTimeToPST(detail?.created_at,timeZone, role) : ''}</div>
                        </div>
                        <div className="col-4">
                        <div><strong>Created By:</strong> {detail?.fname} {detail?.lname}</div>
                           {role <= 2 && 
                            <div className='order-status'>
                                <div><strong>Internal Status :</strong></div>
                                <div><select name="" id="" value={selectedValue} onChange={(e) => changeStatus(e)} >
                                    <option value="">Select Status</option>
                                    <option value="0" >In Service</option>
                                    <option value="1" >Close</option>
                                    <option value="2" >Pending</option>
                                </select></div>
                                <div><a className='order-search' onClick={handleOrderStatusChange}>Save</a></div>
                            </div>
                            }
                        </div>
                    </div>
                     <div className='common-white-shadow-background setting-section'>
                        <OrderSummaryLog ordertype={detail} summary={orderSummary} role={role} />
                    </div>
                    
                    <div className="common-white-shadow-background setting-section">
                        <OrderDetailFilter detail={detail} carriers={carriers} onFilter={handleFilterData} role={role} />
                        <div className="row">
                        <div className=" setting-section haft-whitebox">
                            <div className="search-result-section">
                                <h3></h3>
                                {/* <div className="export-btn newrow"><a className="newrow" href="#">Export</a></div> */}
                            </div>
                                {(detail?.order_type === 4 || detail?.order_type === 5) ?
                                    <table className="tablebox search-result-table">
                                    <thead>
                                    <tr>
                                        <th>Overall Status</th>
                                        <th>Alias</th>
                                        <th>Origination ANI</th>
                                        <th>Match Length</th>
                                        <th>Origination DNIS</th>
                                        <th>Match Length</th>
                                        <th>Translated ANI</th>
                                        <th>Translated DNIS</th>
                                        <th>ANI Action</th>
                                        <th>DNIS Action</th>
                                        {/* <th>Status</th> */}
                                        {/* <th></th>   */}
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {orders.map((mapping, index) => (
                                        <tr key={mapping.id}>
                                            <td>{mapping.user_status == 2 ? "Failed" : (mapping.user_status == 1 ? "Complete" : "In Progress")}</td>
                                            <td>{mapping.alias}</td>
                                            <td>{mapping.original_ani}</td>
                                            <td>{mapping.ani_min_length} - {mapping.ani_max_length}</td>
                                            <td>{mapping.original_dnis}</td>
                                            <td>{mapping.dnis_min_length} - {mapping.dnis_max_length}</td>
                                            <td>{mapping.translated_ani}</td>
                                            <td>{mapping.translated_dnis}</td>
                                            <td>{mapping.ani_action == 0 ? "all" : (mapping.ani_action == 2 ? "passthrough" : (mapping.ani_action == 3 ? "prepend" : "match"))}</td>
                                            <td>{mapping.dnis_action == 0 ? "all" : (mapping.dnis_action == 2 ? "passthrough" : (mapping.dnis_action == 3 ? "noLNP" : (mapping.dnis_action == 4 ? "prepend" : "match")))}</td>
                                            {/* <td>{user.role === 1 ? 'Super Admin' : user.role === 2 ? 'Support User' : user.role === 3 ? 'Reseller Admin' : user.role === 4 ? 'Customer admin' :  user.role === 5 ? 'Customer user' : 'Reseller support'}</td> */}
                                            
                                            {/* <td>{user.is_active === 1 ? <a href="#" className="true">True</a> : <a href="#" className="false">False</a> }</td> */}
                                            
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                                    :
                                    <table className="tablebox search-result-table">
                                        <thead>
                                            <tr>
                                                <th>Tel Number</th>
                                                {detail?.order_type == 0 && <th>Province</th>}
                                                {detail?.order_type == 0 && <th>Rate Center</th>}
                                                {detail?.order_type != 1 && <th>Route Option</th>}
                                                {role <= 2 && <th>Carrier</th>}
                                                {role <= 2 && <th>CNAM</th>}
                                                {role <= 2 && <th>E911</th>}
                                                {role <= 2 && <th>Sansay</th>}
                                                {role <= 2 && <th>ROME</th>}
                                                {role <= 2 && (detail?.order_type == 0 || detail?.order_type == 2) && <th>Carrier Status</th>}
                                                <th>Overall Status</th>





                                            </tr>
                                        </thead>
                                        <tbody>
                                            {orders?.length > 0 && orders.map((order, index) => (

                                                <tr key={index}>
                                                    <td>{order.tn_number}</td>
                                                    {detail?.order_type == 0 && <td>{order.province}</td>}
                                                    {detail?.order_type == 0 && <td>{order.rate_center}</td>}
                                                    {detail?.order_type != 1 && <td>{order.route_option}</td>}
                                                    {role <= 2 && <td>{getCarrierName(order.number_type)}</td>}
                                                    {role <= 2 && <td>{order.number_status == 2 ? "-" : (order.cname_status == 1 ? order.cname_label : (order.cname_status == 2 ? "Failed" : (order.cname_status == 0 && order.cname_flag == 1 ? "In Progress" : "-")))}</td>}
                                                    {role <= 2 && <td>{order.e911_status == 1 ? "Complete" : (order.e911_status == 2 ? "Failed" : "-")}</td>}
                                                    {role <= 2 && <td>{order.number_status == 2 ? "-": (order.sansay_status == 1 ? "Complete" : (order.sansay_status == 2 ? "Failed" : "-"))}</td>}
                                                    {role <= 2 && <td>{order.number_status == 2 ? "-" : (order.rome_status == 1 ? "Complete" : (order.rome_status == 2 ? "Failed" : "-"))}</td>}
                                                    {role <= 2 && (detail.order_type == 0 || detail?.order_type == 2) && <td>{order.number_status == 2 ? "Failed" : (order.number_status == 1 ? "Complete" : "In Progress")}</td>}
                                                    {detail.order_type == 0 && order.cname_flag == 1 && <td>{(order.cname_status == 2 && order.number_status == 2) ? "Failed" : ((order.cname_status == 1 && order.number_status == 1) ? "Complete" : (((order.cname_status == 2 && order.number_status == 1) || (order.user_status == 1 && order.number_status == 2)) ? "Failed" : "In Progress"))}</td>}
                                                    {detail.order_type == 0 && order.cname_flag == 0 && <td>{order.user_status == 2 ? "Failed" : (order.user_status == 1 ? "Complete" : "In Progress")}</td>}
                                                    {detail.order_type != 0 && <td>{order.user_status == 2 ? "Failed" : (order.user_status == 1 ? "Complete" : "In Progress")}</td>}


                                                    {/* {detail.order_result && detail.order_result['numberType'] !== undefined && 
                                            <>
                                                { role <= 2 && detail.order_result && detail.order_result['numberType'] !== 2  && 
                                                    <td>
                                                        {(role <= 2 && detail.order_result && (detail.order_result['numberType'] === undefined || detail.order_result['numberType'] === 0) && detail.order_result['status']) && (
                                                            <div>
                                                                {detail.order_result['status'].split(/[\[\],]/).map((item, index) => {
                                                                  const trimmedItem = item.trim();
                                                                  if (trimmedItem === 'Error Message:') {
                                                                    return null;
                                                                  }
                                                                  return <p key={index}>{trimmedItem}</p>;
                                                                })}
                                                            </div>
                                                        )}
                                                        {(role <= 2 && detail.order_result && detail.order_result['numberType'] !== undefined && detail.order_result['numberType'] === 1) && (
                                                            <div>{detail.order_result['status']}</div>
                                                        )}
                                                    </td>
                                                }
                                                {role <= 2 && detail.order_result && (detail.order_result['numberType'] == undefined || detail.order_result['numberType'] == 0)  && detail.order_result['result'] && 
                                                    <td>
                                                        {detail.order_result && detail.order_result['result'] && detail.order_result['result'].find((result) =>
                                                            result.includes(order.tn_number.toString())
                                                            ) ? ( 
                                                                detail.order_result['result']
                                                                .find((result) => result.includes(order.tn_number.toString()))
                                                            ) : (       
                                                                detail.order_result['result'] ? <p>Success</p> : ''
                                                            )}
                                                    </td>
                                                }     
                                                {role <= 2  && detail.order_result && (detail.order_result['numberType'] == undefined || detail.order_result['numberType'] == 0 ||  detail.order_result['numberType'] == 1 ) && detail.order_result['sansayResult'] && 
                                                    <td>
                                                        { detail.order_result['sansayResult'][0].response}
                                                    </td>
                                                }                                                    
                                                { detail.order_result &&  detail.order_result['inteliquentAll'] !== undefined && detail.order_result['sansayAll'] !== undefined && ((detail.order_result['order'] !== undefined)  || ((detail.order_result['numberType'] !== undefined) && !detail.order_result['cancel'])) &&
                                                    ((detail.order_result['inteliquentAll'] == 0 &&  detail.order_result['sansayAll'] == true) ? <td>Completed</td> : 
                                                    ((detail.order_result['inteliquentAll'] == 0 &&  detail.order_result['sansayAll'] == false) ? <td>In Progress</td> : 
                                                    ((detail.order_result['inteliquentAll'] == 1 &&  (detail.order_result['sansayAll'] == true || detail.order_result['sansayAll'] == false)) ? <td>In Progress</td> : 
                                                    ((detail.order_result['inteliquentAll'] == 2 &&   detail.order_result['sansayAll'] == false) ? <td>Not Successful</td> : <td></td>))))                                        
                                                }
                                                {detail.order_result &&  (detail.order_result['inteliquentAll'] !== undefined || detail.order_result['bandwidthNumber'] !== undefined) && ((detail.order_result['features'] !== undefined) && (detail.order_result['numberType'] !== undefined)) &&
                                                    ((detail.order_result['inteliquentAll'] == 0 ||  detail.order_result['bandwidthNumber'] == 0) ? <td>Completed</td> : 
                                                    ((detail.order_result['inteliquentAll'] == 0 ||  detail.order_result['bandwidthNumber'] == 0) ? <td>In Progress</td> :  
                                                    ((detail.order_result['inteliquentAll'] == 2 ||   detail.order_result['bandwidthNumber'] == 2) ? <td>Not Successful</td> : <td></td>)))                                        
                                                }
                                                {detail.order_result &&  detail.order_result['inteliquentAll'] !== undefined && detail.order_result['sansayAll'] !== undefined && ((detail.order_result['cancel'] !== undefined)) &&
                                                    ((detail.order_result['inteliquentAll'] == 0) && (detail.order_result['sansayAll'] == true || (detail.order_result['sansayAll'] == false)) ? <td>Cancelled</td> :  
                                                    ((detail.order_result['inteliquentAll'] == 2) && (detail.order_result['sansayAll'] == true || detail.order_result['sansayAll'] == false) ? <td>Not Successful</td> : 
                                                    ((detail.order_result['inteliquentAll'] == 1) && (detail.order_result['sansayAll'] == true || detail.order_result['sansayAll'] == false) && detail.order_result['result'] &&  detail.order_result['result']
                                                    .find((result) => result.includes(order.tn_number.toString())) ? <td>Not Successful</td> : <td>Cancelled</td> ) ) )                                        
                                                }                                                                                
                                            </>
                                        } */}

                                                </tr>
                                            ))}

                                        </tbody>
                                    </table>}
                               
                                {/* <div className="recored-setup"> */}
                                    
                                     {totalItem > 20 && (<Pagination
                                  activePage={page}
                                  itemsCountPerPage={20}
                                  totalItemsCount={totalItem}
                                  pageRangeDisplayed={5}
                                  onChange={handlePageChange.bind(this)}
                                />)}
                                {/* </div> */}
                            </div>
                        </div>
                    </div>
                    {role <= 2 && <div className="common-white-shadow-background setting-section">
                        <div className="search-result-section"><h3>Deleted DMTs</h3></div>
                        <table className="tablebox search-result-table">
                            <thead>
                                <tr>
                                    <th>Alias</th>
                                    <th>Origination ANI</th>
                                    <th>Match Length</th>
                                    <th>Origination DNIS</th>
                                    <th>Match Length</th>
                                    <th>Translated ANI</th>
                                    <th>Translated DNIS</th>
                                    <th>ANI Action</th>
                                    <th>DNIS Action</th>
                                </tr>
                            </thead>
                            {dmtList && dmtList[0]?.delete?.length > 0 && <tbody>
                                {dmtList[0]?.delete.map((mapping, index) => (
                                    <tr key={mapping.id}>
                                        <td>{mapping.alias}</td>
                                        <td>{mapping.original_ani}</td>
                                        <td>{mapping.ani_min_length} - {mapping.ani_max_length}</td>
                                        <td>{mapping.original_dnis}</td>
                                        <td>{mapping.dnis_min_length} - {mapping.dnis_max_length}</td>
                                        <td>{mapping.translated_ani}</td>
                                        <td>{mapping.translated_dnis}</td>
                                        <td>{mapping.ani_action == 0 ? "all" : (mapping.ani_action == 2 ? "passthrough" : (mapping.ani_action == 3 ? "prepend" : "match"))}</td>
                                        <td>{mapping.dnis_action == 0 ? "all" : (mapping.dnis_action == 2 ? "passthrough" : (mapping.dnis_action == 3 ? "noLNP" : (mapping.dnis_action == 4 ? "prepend" : "match")))}</td>
                                    </tr>
                                ))}
                            </tbody>}
                        </table>
                    </div>}
                    {role <= 2 && <div className="common-white-shadow-background setting-section">
                        <div className="search-result-section"><h3>DMTs Still Exist in the Sansay</h3></div>
                        <table className="tablebox search-result-table">
                            <thead>
                                <tr>
                                    <th>Alias</th>
                                    <th>Origination ANI</th>
                                    <th>Match Length</th>
                                    <th>Origination DNIS</th>
                                    <th>Match Length</th>
                                    <th>Translated ANI</th>
                                    <th>Translated DNIS</th>
                                    <th>ANI Action</th>
                                    <th>DNIS Action</th>
                                </tr>
                            </thead>
                            {dmtList && dmtList[0]?.pending?.length > 0 && <tbody>
                                {dmtList[0]?.pending.map((mapping, index) => (
                                    <tr key={mapping.id}>
                                        <td>{mapping.alias}</td>
                                        <td>{mapping.original_ani}</td>
                                        <td>{mapping.ani_min_length} - {mapping.ani_max_length}</td>
                                        <td>{mapping.original_dnis}</td>
                                        <td>{mapping.dnis_min_length} - {mapping.dnis_max_length}</td>
                                        <td>{mapping.translated_ani}</td>
                                        <td>{mapping.translated_dnis}</td>
                                        <td>{mapping.ani_action == 0 ? "all" : (mapping.ani_action == 2 ? "passthrough" : (mapping.ani_action == 3 ? "prepend" : "match"))}</td>
                                        <td>{mapping.dnis_action == 0 ? "all" : (mapping.dnis_action == 2 ? "passthrough" : (mapping.dnis_action == 3 ? "noLNP" : (mapping.dnis_action == 4 ? "prepend" : "match")))}</td>
                                    </tr>
                                ))}
                            </tbody>}
                        </table>
                    </div>}
                    {role <= 2 && <div className="common-white-shadow-background setting-section">
                        <div className="search-result-section"><h3>DMTs Still Exist in the BAP</h3></div>
                        <table className="tablebox search-result-table">
                            <thead>
                                <tr>
                                    <th>Alias</th>
                                    <th>Origination ANI</th>
                                    <th>Match Length</th>
                                    <th>Origination DNIS</th>
                                    <th>Match Length</th>
                                    <th>Translated ANI</th>
                                    <th>Translated DNIS</th>
                                    <th>ANI Action</th>
                                    <th>DNIS Action</th>
                                </tr>
                            </thead>
                            {dmtList && dmtList[0]?.portal?.length > 0 &&<tbody>
                                {dmtList[0]?.portal.map((mapping, index) => (
                                    <tr key={mapping.id}>
                                        <td>{mapping.alias}</td>
                                        <td>{mapping.original_ani}</td>
                                        <td>{mapping.ani_min_length} - {mapping.ani_max_length}</td>
                                        <td>{mapping.original_dnis}</td>
                                        <td>{mapping.dnis_min_length} - {mapping.dnis_max_length}</td>
                                        <td>{mapping.translated_ani}</td>
                                        <td>{mapping.translated_dnis}</td>
                                        <td>{mapping.ani_action == 0 ? "all" : (mapping.ani_action == 2 ? "passthrough" : (mapping.ani_action == 3 ? "prepend" : "match"))}</td>
                                        <td>{mapping.dnis_action == 0 ? "all" : (mapping.dnis_action == 2 ? "passthrough" : (mapping.dnis_action == 3 ? "noLNP" : (mapping.dnis_action == 4 ? "prepend" : "match")))}</td>
                                    </tr>
                                ))}
                            </tbody>}
                        </table>
                    </div>}
                    {role <= 2 && 
                        <OrderDetailLog detail={detail}  />
                    }
                    {/* {role <= 2 && detail.order_type == 1 && <OrderDetailLog detail={detail}  />} */}
                    {/* {role <= 2 &&  detail.order_result && detail.order_result['carrierType'] == undefined &&  
                        detail.order_result['numberType'] !== 2 ? 
                            (
                                <div className="row order-detail">
                                    {detail.order_result ? (
                                        <>
                                            <div className="col-6">
                                                <div className="stauts-table">
                                                    {(detail.order_result['numberType'] !== undefined && detail.order_result['numberType'] === 1) ? <strong>Bandwidth Response: </strong>  : <strong>Carrier Response: </strong>  }

                                                    <div className={`${
                                                        detail.order_result?.['status'] &&
                                                        detail.order_result['status']
                                                        .split(/[\[\],]/)
                                                        .filter(item => item.trim() !== 'Error Message:' && item.trim() !== '')
                                                        .length > 1
                                                        ? 'status-value status-tag'
                                                        : 'status-tag'
                                                    }`}>
                                                    {detail.order_result?.['status'] && detail.order_result['status'].split(/[\[\],]/).map((item, index) => {
                                                            const trimmedItem = item.trim();
                                                            if (trimmedItem === 'Error Message:') {

                                                                return null;
                                                            }
                                                        return <p key={index}> {trimmedItem}</p>;
                                                    })}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                {(detail.order_result['numberType'] == undefined || detail.order_result['numberType'] === 0) && <div><strong>Carrier Status Code:</strong> {detail.order_result['statusCode']}</div>}
                                            </div>
                                            {detail.order_result['sansayResult'] && (
                                                <div className="col-6">
                                                    {detail.order_result['sansayResult'].map((item, index) => (
                                                      <div key={index}>
                                                        <strong>Sansay {item.name} Response: </strong>
                                                          {item.response}
                                                      </div>
                                                    ))}
                                                </div>
                                            )}
                                            <div className="col-6">
                                                {detail.order_result['result'] && <div className="stauts-table">
                                                    <strong>Carrier Result:</strong>
                                                    <div className={`${Array.isArray(detail.order_result['result']) && detail.order_result['result'].length > 1 ? 'status-value status-tag' : 'status-tag'}`}>
                                                        {Array.isArray(detail.order_result['result']) ? (
                                                            detail.order_result['result'].map((item, index) => (
                                                                <p key={index}>{item}</p>
                                                            ))
                                                            ) : (
                                                            detail.order_result['result']
                                                        )}
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            {Object.keys(detail.order_result).map((key) => (
                                                key !== 'bandwidthId' && key !== 'processingStatus' && key !== 'bandwidthNumber' && key !== 'numberType' && key !== 'sansayResult' && key !== 'features' && key !== 'cancel' && key !== 'order' && key != "sansayAll" && key != "inteliquentAll" && key !='orderId' && key != 'status'  && key !='statusCode' && key !='result' && (
                                              <div className="col-6" key={key}>
                                                <strong>{key}:</strong> {detail.order_result[key]}
                                              </div>)
                                            ))}
                                        </>   
                                    ) : (
                                        <>
                                            <div className="col-4">
                                                <div><strong>Carrier Response:</strong> {detail.message}</div>
                                            </div>
                                            <div className="col-4">
                                                <div><strong>Carrier Status Code:</strong> {detail.status_code}</div>
                                            </div>
                                        </>
                                    )}
                                </div>
                            ) : (
                                role <= 2 && detail.order_result && detail.order_result['carrierType'] == undefined &&  
                                    <>
                                        <div className="row order-detail">
                                            {detail.order_result && detail.order_result['bandwidthNumber'] !== undefined && ( detail.order_result['bandwidthNumber'] === 0  || detail.order_result['bandwidthNumber'] === 1 || detail.order_result['bandwidthNumber'] === 2) && (<div className="col-6">
                                                <div >
                                                    <div><strong>Bandwidth Order id:</strong> {detail.order_result['bandwidthId']}</div>
                                                </div>
                                                <div >
                                                    <div><strong>Bandwidth Status:</strong> {detail.order_result['processingStatus']}</div>
                                                </div>
                                            </div>)}
                                            {detail.order_result &&  detail.order_result['inteliquentAll'] !== undefined && (detail.order_result['inteliquentAll'] === 0  || detail.order_result['inteliquentAll'] === 1 || detail.order_result['inteliquentAll'] === 2) && (
                                                <div className="col-6">
                                                    <div >
                                                        <div><strong>Carrier Order id:</strong> {detail.order_id}</div>
                                                    </div>
                                                <div >
                                                <div><strong>Carrier Response:</strong> {detail.message}</div>
                                            </div>
                                            <div >
                                                {detail.order_result['result'] && <div className="stauts-table">
                                                    <strong>Carrier Response:</strong>
                                                    <div className={`${Array.isArray(detail.order_result['result']) && detail.order_result['result'].length > 1 ? 'status-value status-tag' : 'status-tag'}`}>
                                                        {Array.isArray(detail.order_result['result']) ? (
                                                            detail.order_result['result'].map((item, index) => (
                                                                <p key={index}>{item}</p>
                                                            ))
                                                            ) : (
                                                            detail.order_result['result']
                                                        )}
                                                        </div>
                                                </div>}
                                            </div>
                                            </div>)}
                                        </div>
                                    </>
                        )
                    }  */}
                </div>
            </div>
        </div>
    )
}
 
export default ViewOrderDetail