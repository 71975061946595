/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios';
import jwt_decode from "jwt-decode";
import Navbar from "./Navbar";
import Header from "./Header";
import baseUrl from "./BaseUrl";
import Loader from "./Loader";
import { v4 as uuidv4 } from 'uuid';
import { useNavigate, useParams } from 'react-router-dom';
 
const EditUser = () => {
    // const { id } = useParams();
    const [name, setName] = useState('');
    const [user_id, setUserId] = useState('');
    const [role, setrole] = useState('');
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [msg, setMsg] = useState('');
    const [isSuccess, setIsSuccess] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [popupOpen, setPopupOpen] = useState(false); 
    const [forgotButtonDisabled, setForgotButtonDisabled] = useState(false);
    const [copied, setCopied] = useState(false);
    const [countUpdated, setCountUpdated] = useState(false);
    const [formData, setFormData] = useState({
        user_role: '0',
        cname: '',
        cid: '',
        uid:'',
        fname: '',
        lname: '',
        email: '',
        officephone: '',
        mobilephone: '',
        api_enable_switch: false,
        api_username:'',
        api_secret:"",
        company_api_enable_switch:''
    });
    const [accessRight, setAccessRight] = useState({
        createuser: false,
        edituser: false,
        deleteuser: false,
        viewuser: false,
        ordernewnumber: false,
        viewtransactions: false,
        modifynumber: false,
        cancelnumber: false,
        viewinventory: false
    });
    const [setting, setSetting] = useState({
        disconnectSwitch: false,
        disconnectday: 0,
    });
    const [trunkData, setTrunksData] = useState([]);
    const [whiteLabelData, setWhiteLabelData] = useState([]);
    const [access, setAccess] = useState([]);

    const navigate = useNavigate();
 
    useEffect(() => {
        refreshToken();
      }, []);
    
      useEffect(() => {
        if (token) {
        //   getUserCompany();
          getUserDetail();
          getUserAccess();
        }
      }, [token]);
 
    const refreshToken = async () => {
        try {
            setPopupOpen(true);
            const response = await axios.get(`${baseUrl}/token`);
            
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            console.log(decoded);
            setrole(decoded.role);
            setName(decoded.name);
            setUserId(decoded.userId);
            setExpire(decoded.exp);
        } catch (error) {
           
            if (error.response) {
                // setError("Unauthorized");
                navigate("/");  
                window.location.reload();
            }
        }
    }
    const getUserAccess = async () => {
        try {
            const response = await axios.get(`${baseUrl}/user-access`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
           
            console.log(response.data.results);
            setAccess(response.data.results);
            // setIsLoading(false);
            
        } catch (error) {
           console.log(error);
        //    setIsLoading(false);
            // if (error.response) {
            //     // setError("Unauthorized");
            //     navigate("/");  
            //     window.location.reload();
            // }
        }
    }

    const hasPermission = (permission) => {
        return access.some((item) => item.access === permission);
      };
    const handleSwitchChange = (event) => {
        const { name, checked } = event.target;
        setSetting((prevSetting) => ({
            ...prevSetting,
            [name]: checked,
        }));
    };

    const handleDayChange = (event) => {
        const { name, value } = event.target;
        setSetting((prevSetting) => ({
            ...prevSetting,
            [name]: parseInt(value),
        }));
    };

    const handleCheckboxChange = (event, name, type) => {
        const isChecked = event.target.checked;
        if(type === 0){
            if (isChecked) {
                setTrunksData([...trunkData, name]);
            } else {
                setTrunksData(trunkData.filter(n => n !== name));
            }
        }else{
            if (isChecked) {
                setWhiteLabelData([...whiteLabelData, name]);
            } else {
                setWhiteLabelData(whiteLabelData.filter(n => n !== name));
            }
        }
        
    };

   
   
    const handleE911SwitchChange = async(event) => {
        const { name, checked } = event.target;
        if(checked){
            // const apiKey = uuidv4();
            const api_secret = generatePassword();
            const api_username = uuidv4();

            if(window.confirm("Are you sure you want to enable API access?")){
                setFormData((prevData) => ({
                    ...prevData,
                    [name]: 1,
                    api_username:api_username,
                    api_secret: api_secret
                })); 
                try {
                  const response = await axios.post(`${baseUrl}/cognito-user-detail`, {
                    formData: { ...formData, [name]: checked, api_secret: api_secret,api_username:api_username }             
                });
                  console.log('Response:', response.data);
                } catch (error) {
                  console.error('Error fetching data:', error);
                }
            } 
            console.log(formData);
        }else{
            setFormData((prevData) => ({
                ...prevData,
                [name]: checked,
                api_username:"",
                api_secret: "",
                api_access_count: 1000,
            }));
            try {
                const response = await axios.post(`${baseUrl}/cognito-user-detail`, {
                  formData: { ...formData, [name]: checked, api_secret: "",api_username:"",api_access_count:null }             
                });
                console.log('Response:', response.data);
              } catch (error) {
                console.error('Error fetching data:', error);
              }
        }
    };

    const generatePassword = () => {
        const length = 25;
        const numbers = '0123456789';
        const lowerCase = 'abcdefghijklmnopqrstuvwxyz';
        const upperCase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        const specialCharacters = '^$*.[]{}()?-@#%&/,><|_~+=';
    
        const allCharacters = numbers + lowerCase + upperCase + specialCharacters;
    
        let password = '';
        let hasNumber = false;
        let hasLowerCase = false;
        let hasUpperCase = false;
        let hasSpecialCharacter = false;
    
        // Ensure the password contains at least one of each required character type
        password += numbers[Math.floor(Math.random() * numbers.length)];
        hasNumber = true;
    
        password += lowerCase[Math.floor(Math.random() * lowerCase.length)];
        hasLowerCase = true;
    
        password += upperCase[Math.floor(Math.random() * upperCase.length)];
        hasUpperCase = true;
    
        password += specialCharacters[Math.floor(Math.random() * specialCharacters.length)];
        hasSpecialCharacter = true;
    
        // Fill the rest of the password length with random characters
        for (let i = password.length; i < length; i++) {
            const character = allCharacters[Math.floor(Math.random() * allCharacters.length)];
            password += character;
    
            if (numbers.includes(character)) {
                hasNumber = true;
            } else if (lowerCase.includes(character)) {
                hasLowerCase = true;
            } else if (upperCase.includes(character)) {
                hasUpperCase = true;
            } else if (specialCharacters.includes(character)) {
                hasSpecialCharacter = true;
            }
        }
    
        // Shuffle the password to ensure randomness
        password = password.split('').sort(() => Math.random() - 0.5).join('');
    
        // Validate the generated password and regenerate if it doesn't meet all criteria
        if (!hasNumber || !hasLowerCase || !hasUpperCase || !hasSpecialCharacter) {
            return generatePassword();
        }
    
        return password;
    };

    const partiallyHideSecret = (secret) => {
        if(secret){
            const visibleChars = 4;
            const hiddenChars = secret.length - visibleChars;
            return `${secret.slice(0, visibleChars)}${'*'.repeat(hiddenChars)}`;
        }else{
           return secret
        }
       
    };

    
    const handleCopy = async (e, value) => {
        e.preventDefault();
        try {
          await navigator.clipboard.writeText(value);
          setCopied(true);
          setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
        } catch (error) {
          console.error('Failed to copy:', error);
        }
    };


    const getUserDetail = async () => {
        setPopupOpen(true);
        const response = await axios.get(`${baseUrl}/user-profile/${user_id}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        console.log(response.data);
        setPopupOpen(false);
        const userData = response.data.user[0];
        const companyDetailsData = response.data.companyDetailsData[0];
        setFormData({
            user_role: userData.role, // Convert to string if needed
            cname: userData.cname,
            cid: userData.cid,
            uid: userData.id,
            fname: userData.fname,
            lname: userData.lname,
            email: userData.email,
            officephone: userData.office_phone,
            mobilephone: userData.mobile_phone,
            api_enable_switch:userData.api_enable_switch,
            api_username:userData.api_username,
            api_secret:userData.api_secret,
            company_api_enable_switch:companyDetailsData.api_enable_switch
          });
          const userAccessData = response.data.user_access;

          const updatedAccessRight = { ...accessRight }; // Create a copy of the state
      
          userAccessData.forEach((access) => {
            const { access: accessName, value } = access;
            updatedAccessRight[accessName] = value === 1; // Convert value to boolean
          });
      
          setAccessRight(updatedAccessRight);

          const userSettingData = response.data.user_setting[0];
          if (userSettingData) {
            setSetting({
                disconnectSwitch: userSettingData.disconnect_switch === 1,
                disconnectday: userSettingData.disconnect_day,
            });
        } 
        console.log(setting.disconnectSwitch);

          const userTrunk = response.data.user_trunk;;
          setTrunksData(userTrunk);
          const userLabel = response.data.user_white_label;;
          setWhiteLabelData(userLabel);
        // console.log(userData);
    };
    const [validationErrors, setValidationErrors] = useState({
        user_role: false,
        email: false,
        cname: false,
        fname: false,
        lname: false,
        officephone: false,
        mobilephone: false

    });
    const formRef = useRef(null);
    const validateEmail = (email) => {
        // Implement your email validation logic here
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        const newValidationErrors = {
            user_role: formData.user_role === '0',
            email: !validateEmail(formData.email),
            cname: formData.cname === '',
            fname: formData.fname === '',
            lname: formData.lname === '',
            officephone: !/^\d{1,10}$/.test(formData.officephone),
            mobilephone: !/^\d{1,10}$/.test(formData.mobilephone)


          };
          setValidationErrors(newValidationErrors);
      
          // Check if there are any validation errors
          const hasErrors = Object.values(newValidationErrors).some((error) => error);
      
          if (hasErrors) {
            
            // Find the first invalid input element
            const firstInvalidInput = formRef.current.querySelector('.invalid');
            console.log(firstInvalidInput);
            if (firstInvalidInput) {
              // Scroll to the first invalid input element
              firstInvalidInput.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
          } else {
            
            // If no validation errors, proceed with form submission
            console.log('Form submitted with data:', formData);
            console.log(token);
            const rightsArray = Object.entries(accessRight).map(([key, value]) => ({
                name: key,
                value: value ? 1 : 0,
              }));
             
              const combinedData = {
                formData: formData,
                rightsArray: rightsArray,
                setting :setting,
                trunks: trunkData,
                whitelabel:whiteLabelData
              };
              console.log(combinedData);
            try {
                setButtonDisabled(true);
                setPopupOpen(true);
                await axios.post(`${baseUrl}/user-profile/${user_id}`,combinedData,{headers: {
                    Authorization: `Bearer ${token}`
                }});
                setIsSuccess(true);
                navigate("/dashboard");
            } catch (error) {
                setPopupOpen(false);
                setButtonDisabled(false);
                if (error.response) {
                    setIsSuccess(false);
                    setMsg(error.response.data.msg);
                }
            }
          }
    }
    const ChangePassword = async (e) =>  {
        e.preventDefault();
       
        try {
            setPopupOpen(true);
            setForgotButtonDisabled(true);
            const resposne = await axios.post(`${baseUrl}/forgot-password`, {
                email: formData.email
            });
            setForgotButtonDisabled(false);
            setIsSuccess(true);
            setPopupOpen(false);
            setMsg(resposne.data.msg);
            // navigate("/login");
        } catch (error) {
            setForgotButtonDisabled(false);
            setPopupOpen(false);
            if (error.response) {
                setIsSuccess(false);
                setMsg(error.response.data.msg);
            }
        }
    }
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'user_role' && value <= 2) {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
                cname: '1' // Set cname to '1' if user_role is less than or equal to 2
            }));
            setTrunksData([]);
            setWhiteLabelData([]);
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value
            }));
        }
    
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          [name]: false
        }));
    };

      const accessRightLabels = {
        createuser: 'Create Users',
        edituser: 'Edit Users',
        deleteuser: 'Delete Users',
        viewuser: 'View Users',
        ordernewnumber: 'Order New Number',
        viewtransactions: 'View Transations',
        modifynumber: 'Modify Number',
        cancelnumber: 'Cancel Number',
        viewinventory: 'View Inventory'        
      };
    
 
    return (
        <div className="panelbox">
           <Navbar  token={token} access = {access} />
            <div className="right-panel">
                <Header name={name} />
                {popupOpen && (
                     <Loader /> 
                ) }
                <div className="content-page admin-new-addstaff-page">
                    <div className="fullrow">
                        <div className="row user-profile">
                            <div className="col-6">
                                <div className="left-title">
                                    <h1>Edit User</h1>
                                </div>
                            </div>
                            <div className="col-6 edit-inventory-btn">
                                <div className="addnew-button">
                                    <a href="#"className={forgotButtonDisabled ? ' disabled-link' : ''} onClick={ChangePassword}>Change Password</a>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                            <p className={`has-text-centered ${isSuccess ? 'success' : 'error'}`}>{msg}</p>
                                <div className="edit-staff-data-form add-new-staff-form">
                                    <form className="form-box" onSubmit={handleSubmit} ref={formRef}>
                                        <div className="common-white-shadow-background">
                                            <div className="innerboxwidth">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <h3>User Info</h3>
                                                    </div>
                                                    
                                                    {/* <div className="col-6">
                                                        <select name="user_role" id="user_role"  value={formData.user_role} onChange={handleInputChange} className={validationErrors.user_role ? 'invalid' : ''}>
                                                            <option value="0">User Types</option>                
                                                            {role === 0 && <option value="1">Super Admin</option>}
                                                            {role <= 1 && <option value="2">Support User</option>}
                                                            {role <= 2 && <option value="3">Reseller admin user</option>}
                                                            {role <= 3 && <option value="4">Customer admin user</option>}
                                                            {role <= 4 && <option value="5">Customer user</option>}
                                                        </select>
                                                        {validationErrors.user_role && <div className="error-message">Please select a user role.</div>}
                                                    </div> */}
                                                    <div className="col-md-12">
                                                    <label>Company Name</label> 
                                                        {/* <select name="cname" value={formData.cname} onChange={handleInputChange} className={validationErrors.cname ? 'invalid' : ''} >
                                                            <option value="">Select a company</option>
                                                            {company.map((comp, index) => (
                                                              <option key={index} value={comp.id}>
                                                                {comp.cname}
                                                              </option>
                                                            ))}
                                                        </select> */}
                                                        <input type="text" name="cname" defaultValue={formData.cname}
                                                        placeholder="Company Name*"  disabled onChange={handleInputChange} className={validationErrors.cname ? 'invalid' : ''} />
                                                        {validationErrors.cname && <div className="error error-message">Please Enter Company Name.</div>}
                                                    </div>
                                                    <div className="col-md-6">
                                                    <label>First Name</label>
                                                        <input type="text" name="fname" disabled defaultValue={formData.fname} placeholder="First Name*" onChange={handleInputChange} className={validationErrors.fname ? 'invalid' : ''} />
                                                        {validationErrors.fname && <div className="error error-message">Please Enter First Name.</div>}
                                                    </div>
                                                    <div className="col-md-6">
                                                    <label>Last Name</label>
                                                        <input type="text" name="lname" disabled defaultValue={formData.lname} placeholder="Last Name*"  onChange={handleInputChange} className={validationErrors.lname ? 'invalid' : ''} />
                                                        {validationErrors.lname && <div className="error error-message">Please Enter last name.</div>}
                                                    </div>
                                                    <div className="col-sm-12">
                                                    <label>Email</label>
                                                        <input type="email" name="email" disabled defaultValue={formData.email} placeholder="Email"  onChange={handleInputChange} className={validationErrors.email ? 'invalid' : ''}/>
                                                        {validationErrors.email && <div className="error error-message">Please enter a valid email address.</div>}
                                                    </div>

                                                    <div className="col-sm-6">
                                                    <label>Office Phone Number</label>
                                                        <input type="text" name="officephone" defaultValue={formData.officephone}
                                                            pattern="[0-9]*"  placeholder="Office Phone Number*" onChange={handleInputChange} className={validationErrors.officephone ? 'invalid' : ''} />
                                                            {validationErrors.officephone && <div className="error error-message">Phone number should not be longer than 10-digits.</div>}
                                                    </div>
                                                    <div className="col-sm-6">
                                                    <label>Mobile Phone Number</label>
                                                        <input type="text" name="mobilephone" defaultValue={formData.mobilephone}
                                                            pattern="[0-9]*"  placeholder="Mobile Phone Number*" onChange={handleInputChange} className={validationErrors.mobilephone ? 'invalid' : ''} />
                                                            {validationErrors.mobilephone && <div className="error error-message">Phone number should not be longer than 10-digits.</div>}
                                                    </div>
                                                    {/* {formData.user_role >= '2' && <div className="col-sm-12">
                                                        <p>Access Rights</p>
                                                        <div className="checkboximage">
                                                        {Object.entries(accessRight).map(([key, value]) => (
                                                            <label key={key} htmlFor={key}>
                                                            <input type="checkbox" name={key} id={key} checked={value} onChange={(e) =>
                                                                setAccessRight((prevRights) => ({
                                                                  ...prevRights,
                                                                  [key]: e.target.checked,
                                                                }))}
                                                            />
                                                            {accessRightLabels[key]}
                                                            </label>
                                                        ))}
                                                        </div>
                                                    </div>} */}
                                                </div>
                                            </div>
                                        </div>
                                        {(formData.user_role == '4' && formData.company_api_enable_switch == 1) && <div className="col-sm-12">
                                        <div className="common-white-shadow-background setting-section">
                                            <div className="innerboxwidth">
                                                <div className="row">
                                                    <div className="header-row">
                                                        <h3>Enable API access</h3>
                                                        {/* <input type="button" className="yellow-button" value="Generate" onClick={handleGenerate} /> */}
                                                    </div>
                                                    
                                                    <div className="settingbox">
                                                        <label>Api Access</label>
                                                        <div className="switchbtn">
                                                            <input type="checkbox" id="api_enable_switch" className="checkbox" checked={formData.api_enable_switch === 1} name="api_enable_switch" onChange={handleE911SwitchChange} /> <label
                                                                htmlFor="api_enable_switch" className="toggle">
                                                                <p>
                                                                  
                                                                </p>
                                                            </label>
                                                        </div>
                                                    </div>

                                                    {formData.api_enable_switch == 1 && <div className='api-key-secret'>
                                                        <div className="row">
                                                            <div className="col-sm-2">
                                                                <label>Api Key</label>
                                                            </div>
                                                            <div className="col-sm-8">
                                                                <input type="text" name="api_ket" readOnly placeholder="Api Key" value={partiallyHideSecret(formData.api_username)} />
                                                            </div>
                                                            <div className="col-sm-2">
                                                                <input type="button" onClick={(e) => handleCopy(e, formData.api_username)} value="Copy"/>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-sm-2">
                                                                <label>Api Secret</label>
                                                            </div>
                                                            <div className="col-sm-8">
                                                                <input type="text" name="api_secret" readOnly placeholder="Api Secret" value={partiallyHideSecret(formData.api_secret)}  />
                                                            </div>
                                                            <div className="col-sm-2">
                                                                <input type="button" onClick={(e) => handleCopy(e, formData.api_secret)} value="Copy"/>
                                                            </div>
                                                        </div>
                                                        {copied && <span>Copied!</span>}
                                                        {countUpdated && <span>Access Count Updated!</span>}
                                                    </div>}
                                                </div>
                                            </div>
                                        </div> 
                                    </div>}
                                    {/* {(role == '3' || role == '4') && <div className="common-white-shadow-background setting-section">
                                        <div className="innerboxwidth">
                                            <div className="row">
                                                <h3>Settings</h3>
                                                <div className="settingbox">
                                                    <label>Minimum Disconnect Option</label>
                                                    <div className="switchbtn">
                                                        <input type="checkbox" id="switch" className="checkbox" disabled checked={setting.disconnectSwitch === true} name="disconnectSwitch"  onChange={handleSwitchChange} /> <label
                                                            htmlFor="switch" className="toggle">
                                                            <p>
                                                               
                                                            </p>
                                                        </label>
                                                    </div>
                                                    <div className="daybox">
                                                        {setting.disconnectSwitch && (

                                                       
                                                        <label htmlFor="">Days
                                                         <input type="text" name="disconnectday"
                                                            placeholder="120" value={setting.disconnectday} onChange={handleDayChange} />
                                                            </label>
                                                            )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>} */}
                                    {/* {(role == '3' || role == '4') && <div className="common-white-shadow-background setting-section">
                                        <div className="row">
                                            <div className="col-7">
                                                <h3>Trunks</h3>
                                            </div>
                                            <div className="col-5">
                                                <input className="search-leave satff-listing-page-search" type="text"
                                                    name="search" placeholder="Search" style={{width:'100%'}} />
                                            </div>
                                            <div className="tablebox">
                                                <div className="fullwidth-table track-table-body staff-listing-admin-table-body">
                                                    <table>
                                                        <thead>
                                                        <tr>
                                                            <th></th>
                                                            <th>TID</th>
                                                            <th colSpan="2">Name</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr>
                                                            <td><input type="checkbox" disabled name="" id="" checked={trunkData.some((item) => item.name === 'ORG BROADBAND-DYNAMICS_LA1')} onChange={(e) => handleCheckboxChange(e, {name:'ORG BROADBAND-DYNAMICS_LA1', value:45265}, 0)} /></td>
                                                            <td>45265</td>
                                                            <td colSpan="2">ORG BROADBAND-DYNAMICS_LA1</td>
                                                        </tr>
                                                        <tr>
                                                            <td><input type="checkbox" disabled name="" id="" checked={trunkData.some((item) => item.name === 'ORG BROADBAND-DYNAMICS_TOR1')} onChange={(e) => handleCheckboxChange(e, {name:'ORG BROADBAND-DYNAMICS_TOR1',  value:45281}, 0)} /></td>
                                                            <td>45281</td>
                                                            <td colSpan="2">ORG BROADBAND-DYNAMICS_TOR1</td>
                                                        </tr>
                                                        <tr>
                                                            <td><input type="checkbox" disabled name="" id="" checked={trunkData.some((item) => item.name === 'ORG BROADBAND-DYNAMICS_MN1')} onChange={(e) => handleCheckboxChange(e, {name:'ORG BROADBAND-DYNAMICS_MN1',  value:45282}, 0)} /></td>
                                                            <td>45282</td>
                                                            <td colSpan="2">ORG BROADBAND-DYNAMICS_MN1</td>
                                                        </tr>
                                                        <tr>
                                                            <td><input type="checkbox" disabled name="" id="" checked={trunkData.some((item) => item.name === 'ORG BROADBAND-DYNAMICS_SLC1')} onChange={(e) => handleCheckboxChange(e, {name:'ORG BROADBAND-DYNAMICS_SLC1',  value:45283}, 0)} /></td>
                                                            <td>45283</td>
                                                            <td colSpan="2">ORG BROADBAND-DYNAMICS_SLC1</td>
                                                        </tr>
                                                        <tr>
                                                            <td><input type="checkbox" disabled name="" id="" checked={trunkData.some((item) => item.name === 'ORG BROADBAND-DYNAMICS_LA2')} onChange={(e) => handleCheckboxChange(e, {name:'ORG BROADBAND-DYNAMICS_LA2', value:45284}, 0)} /></td>
                                                            <td>45284</td>
                                                            <td colSpan="2">ORG BROADBAND-DYNAMICS_LA2</td>
                                                            
                                                        </tr>
                                                        <tr>
                                                            <td><input type="checkbox" disabled name="" id="" checked={trunkData.some((item) => item.name === 'ORG BROADBAND-DYNAMICS_SLC2')} onChange={(e) => handleCheckboxChange(e, {name:'ORG BROADBAND-DYNAMICS_SLC2', value:45554}, 0)} /></td>
                                                            <td>45554</td>
                                                            <td colSpan="2">ORG BROADBAND-DYNAMICS_SLC2</td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>} */}
                                    {/* {(role == '3' || role == '4') && <div className="common-white-shadow-background setting-section">
                                        <div className="row">
                                            <div className="col-7">
                                                <h3>White Label SBC Trunks <img src={`${process.env.PUBLIC_URL}/images/bx-refresh.png`} style={{background: '#f5f5f5', borderRadius: '2px'}} /></h3>
                                            </div>
                                            <div className="col-5">
                                                <input className="search-leave satff-listing-page-search" type="text"
                                                    name="search" placeholder="Search" style={{width:'100%'}} />
                                            </div>
                                            <div className="tablebox">
                                                <div className="fullwidth-table track-table-body staff-listing-admin-table-body">
                                                    <table>
                                                        <thead>
                                                        <tr>
                                                            <th></th>
                                                            <th>TID</th>
                                                            <th colSpan="2">Name</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr>
                                                            <td><input type="checkbox" disabled name="" id="" checked={whiteLabelData.some((item) => item.name === 'LA2_BBD_9644_DID')} onChange={(e) => handleCheckboxChange(e, {name:'LA2_BBD_9644_DID', value:9644}, 1)}/></td>
                                                            <td>9644</td>
                                                            <td colSpan="2">LA2_BBD_9644_DID</td>
                                                        </tr>
                                                        <tr>
                                                            <td><input type="checkbox" disabled name="" id="" checked={whiteLabelData.some((item) => item.name === 'VOIP_DLR_MIN_LD')} onChange={(e) => handleCheckboxChange(e, {name:'VOIP_DLR_MIN_LD', value:1048}, 1)}/></td>
                                                            <td>1048</td>
                                                            <td colSpan="2">VOIP_DLR_MIN_LD</td>
                                                        </tr>
                                                        <tr>
                                                            <td><input type="checkbox" disabled name="" id="" checked={whiteLabelData.some((item) => item.name === 'MN2_BBD_7012_DID')} onChange={(e) => handleCheckboxChange(e, {name:'MN2_BBD_7012_DID', value:7012}, 1)}/></td>
                                                            <td>7012</td>
                                                            <td colSpan="2">MN2_BBD_7012_DID</td>
                                                        </tr>
                                                        <tr>
                                                            <td><input type="checkbox" disabled name="" id="" checked={whiteLabelData.some((item) => item.name === 'MN2_BBD_9644_DID')} onChange={(e) => handleCheckboxChange(e, {name:'MN2_BBD_9644_DID', value:9644}, 1)}/></td>
                                                            <td>9644</td>
                                                            <td colSpan="2">MN2_BBD_9644_DID</td>
                                                        </tr>
                                                        <tr>
                                                            <td><input type="checkbox" disabled name="" id="" checked={whiteLabelData.some((item) => item.name === 'BBD_9644_DID')} onChange={(e) => handleCheckboxChange(e, {name:'BBD_9644_DID', value:9644}, 1)}/></td>
                                                            <td>9644</td>
                                                            <td>BBD_9644_DID</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input type="checkbox" disabled name="" id="" checked={whiteLabelData.some((item) => item.name === 'MIN_BBD_9644_DID')} onChange={(e) => handleCheckboxChange(e, {name:'MIN_BBD_9644_DID', value:9644}, 1)} /></td>
                                                            <td>9644</td>
                                                            <td colSpan="2">MIN_BBD_9644_DID</td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>} */}
                                    <div className="col-12">
                                        <input type="submit" value="Update User" className={buttonDisabled ? 'yellow-button disabled-link' : 'yellow-button'} />
                                    </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            
                
            </div>
        </div>
    )
}
 
export default EditUser