import React, { useEffect, useState } from 'react'
import jwt_decode from "jwt-decode";
import Select from 'react-select';
import Navbar from '../Navbar'
import axios from 'axios';
import baseUrl from '../BaseUrl';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import Header from '../Header';
import Loader from '../Loader';
import Pagination from 'react-js-pagination';
import formatDateTimeToPST from '../PSTDateConvert';
import DatePicker from 'react-datepicker';


const NumberCallHistory = () => {
    const location = useLocation();
    const { compid, filter } = location.state || {};
    const [name, setName] = useState('');
    const [timeZone, seTimeZone] = useState('');
    const [cid, setCid] = useState('')
    const [token, setToken] = useState('');
    const [access, setAccess] = useState([]);
    const [company, setCompany] = useState([]);
    const [expire, setExpire] = useState('');
    const [role, setRole] = useState('');
    const [popupOpen, setPopupOpen] = useState(false);

    const [casAccess, setCASAccess] = useState(false);
    const [showNoAccessMessage,setShowNoMessageAccess] = useState(false)
    const [selectedCompany, setSelectedCompany] = useState(compid);
    const [numberList,setNumberList] = useState([]);
    const [carrierList, setCarrierList] = useState([]);
    const [selectedNumber,setSelectedNumber] = useState('');
    const [selectedCarrier, setSelectedCarrier] = useState('');
    const [searchDate,setSearchDate] = useState('')
    const [displayDate,setDisplayDate] = useState('')
    const [callHistory,setCallHistory] = useState([])
    const [page, setPage] = useState(1);
    const [totalItem, setTotalItem] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        getNumberCallHistory();
        // getUserAccess();
        // getUserCompany();
        // getCarrierList();
    }, []);

    
    const axiosJWT = axios.create();
 
    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(`${baseUrl}/token`);
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setName(decoded.name);
            setExpire(decoded.exp);
            // setCid(decoded.cid)
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    

    const getNumberCallHistory = async (page) => {
        setPopupOpen(true);
        const response = await axiosJWT.get(`${baseUrl}/get-cia-image-textract`, {
            
        });
        setPopupOpen(false);
        setCallHistory(response.data.list)
    }



    const gotoSwitchScreen = (e) => {
        e.preventDefault();
        const state = { state: {compid : selectedCompany}}
        navigate("/cas-management", state);
    }

  return (
    <div className="panelbox">
        <Navbar token={token} access={access}/>
        <div className="right-panel">
            <Header name={name} />
            {popupOpen && (
                     <Loader /> 
                ) }
                <div className="content-page admin-attendance-page">
                    <div className="row">
                        <div className="col-8">
                            <div className="left-title">
                                <h1>TextRact History</h1>
                            </div>
                        </div>
                        <div className="col-4 inventory-btn">
                        <div className="addnew-button">
                            <a href="#" onClick={(e) => gotoSwitchScreen(e)}>Back</a>
                            {/* <Link to="/cas-management"> Back</Link> */}
                        </div>
                    </div>
                    </div>

                        <div className="common-white-shadow-background setting-section">
                            <div className="row">
                                <div className=" setting-section haft-whitebox">
                                    <table className="tablebox search-result-table inventory-table">
                                        <thead>
                                            <tr>
                                                <th>Tel Number</th>
                                                <th>Carrier</th>
                                                <th>Location</th>
                                                <th>Spam Check</th>
                                                <th>Path</th>
                                                <th>All Words</th>
                                                <th>Date-Time</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {callHistory.map((item, index)=>(
                                                <tr key={index}>
                                                    <td>{item.tn_number}</td>
                                                    <td>{item.carrier}</td>
                                                    <td>{item.location}</td>
                                                    <td>{item.spam_check == 1 ? "spam found" : "spam not found"}</td>
                                                    <td>{item.path}</td>
                                                    <td>{item.all_words}</td>
                                                    <td>{formatDateTimeToPST(item.date_time, timeZone, role)}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    
                                </div>
                            </div>
                        </div>
                    
                    
                </div>
        </div>
    </div>
  )
}

export default NumberCallHistory